.ThemeImage {
  max-width: 250px;
  max-height: 300px;
  transition: transform ease-in-out 50ms;
  margin: 30px;
  margin-top: 60px;
  border: 6px solid #0305048a;
  border-radius: 20px;
}

.ThemeImage:hover {
  transform: scale(1.2);
  transition: transform ease-in-out 100ms;
}

.Selected {
  border: 6px solid #e83410ce;
  border-radius: 20px;
}
