.BoosterPackGenerator {
  padding-top: 60px;
  padding-bottom: 60px;
  padding-right: 60px;
  padding-left: 60px;
  width: calc(100vw - 160px);
  height: calc(100vh - 80px);
  overflow-y: scroll;
}
.BoosterPackSelector {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: top;
}

.SetInput {
  flex-grow: 1;
  margin-right: 20px;
}

.GenerateBooster {
  display: flex;
  align-items: center;
}

.BoosterPackCards {
  padding-top: 60px;
  margin-left: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(145px, 1fr));
  grid-gap: 20px;
}

.BoosterPackLoading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100vw - 30vw);
  height: calc(100vh - 30vh);
  min-height: 380px;
}
