.JmpDeck {
  display: flex;
  width: 100vh;
  flex-direction: row;
  justify-content: space-around;
  margin-left: 20vw;
  margin-right: 20vw;
}

.JmpShowAll {
  padding-top: 60px;
  margin-left: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(145px, 1fr));
  grid-gap: 20px;
}

.CardImages {
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: grid;
  grid-template-columns: 1;
  grid-template-rows: 1;
}

.CardImagesGrid {
  height: inherit;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(145px, 1fr));
  grid-gap: 20px;
  width: 100%;
}

.CardList {
  font: 16px black;
  font-family: arial;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.CardName:hover {
  color: #e83410;
  text-decoration: none;
  cursor: pointer;
}
