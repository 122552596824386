.Card {
  width: inherit;
  height: inherit;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto;
  transition: transform ease-in-out 50ms;
  border-radius: 5px;
}

.SmCard {
  width: 145px;
  height: 200px;
}

.LgCard {
  display: initial;
  width: 290px;
  height: 400px;
}

.SmCard:hover {
  transform: scale(1.4);
  transition: transform ease-in-out 100ms;
}

.StackedCard {
  grid-area: 1 / 1 / 1 / 1;
}

.NoCard {
  display: none;
}

.ShowCard {
  display: initial;
}
