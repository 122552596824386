.Header {
  width: 100vw;
  height: 50px;
  background-color: white;
  border-bottom: 2px solid gray;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.HeaderImage {
  padding-top: 6px;
  padding-bottom: 5px;
  margin-left: 10px;
  height: 50px;
}

.HeaderIcon {
  height: 50px;
  width: 50px;
  margin-right: 10px;
  padding-top: 12px;
  padding-bottom: 12px;
  color: black;
  display: flex;
  align-items: center;
}

.HeaderIcon:hover {
  color: gray;
  text-decoration: none;
  cursor: pointer;
}

.HeaderIcon.Active {
  color: #e83410;
}
