.JumpstartGenerator {
  display: inherit;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 60px;
  padding-bottom: 0px;
  height: calc(100vh - 80px);
  width: inherit;
  overflow-y: scroll;
  overflow-x: hidden;
}

.JmpImage {
  min-width: calc(100vw - 480px);
  width: 50vw;
  border-radius: 25px;
  margin-top: 2vh;
}

.NavButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 50px;
  padding-right: 30px;
  padding-left: 30px;
}

.GenerateButton {
  margin-top: 50px;
  padding-right: 30px;
  padding-left: 30px;
}

.JmpMenu {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: 20vw;
  margin-right: 20vw;
  padding-bottom: 20px;
  color: gray;
}

.JmpMenuItem {
  margin-left: 2vw;
  margin-right: 2vw;
  color: black;
  font-weight: bold;
}

.JmpMenuItem:hover {
  color: gray;
  text-decoration: none;
  cursor: pointer;
}
