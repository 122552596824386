.App {
  text-align: center;
  background-color: lightgray;
}

.App-Main {
  background-color: lightgray;
  min-height: calc(100vh - 50px);
  width: 100%;
  display: flex;
  font-size: calc(10px + 2vmin);
}

.Content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-left: 150px;
  transition: padding-left 0.3s ease-in-out;
}

.App-logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 150px;
}

.Button {
  font-size: 18px;
  border-radius: 9px;
  border: 2px solid black;
  transition-duration: 0.1s;
  color: white;
  background-color: gray;
}

.Button:hover {
  background-color: white;
  color: gray;
}

.AppText {
  font-size: calc(14px + 0.4vw);
}

.AppTitle {
  font-size: calc(18px + 0.4vw);
  font-weight: bold;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
