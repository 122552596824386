.JmpUserDeckPage {
  display: inherit;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 60px;
  padding-bottom: 0px;
  height: calc(100vh - 80px);
  width: inherit;
  overflow-y: scroll;
  overflow-x: hidden;
}

.JmpUserDecks {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: inherit;
}
