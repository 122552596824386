.ProfilePage {
  display: inherit;
  flex-direction: column;
  justify-content: left;
  align-items: center;
  padding-top: 60px;
  margin-left: 50px;
  padding-bottom: 0px;
  height: calc(100vh - 80px);
  width: 60vw;
  overflow-y: scroll;
  overflow-x: hidden;
}

.ProfilePageTitle {
  padding-bottom: 20px;
}

.ProfilePageContent {
  text-align: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: top;
}

.ProfilePageItem {
  text-align: left;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: top;
}

.ProfilePageItemTitle {
  display: flex;
  flex-direction: row;
  justify-content: left;
  font-weight: bold;
  padding-right: 5vw;
  width: 50%;
}

.ProfilePageItemInfo {
  display: flex;
  flex-direction: row;
  justify-content: left;
  width: 50%;
}
