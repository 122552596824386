.SideBar {
  width: 170px;
  height: calc(100vh - 90px);
  background-color: white;
  margin-top: 20px;
  padding-top: 40px;
  padding-bottom: 20px;
  border: 2px solid gray;
  border-left: none;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
  position: absolute;
  left: 0px;
  transition: left 0.3s ease-in-out;
}

.NavItem {
  padding-top: 10px;
  font-size: 18px;
  color: black;
  font-weight: bold;
}

.NavItem:hover {
  color: Gray;
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  position: relative;
}

.NavItem:active {
  color: black;
}

.HideButton {
  position: absolute;
  left: 140px;
  top: 20vh;
  height: 50vh;
  width: 30px;
  border: none;
  background-color: rgba(128, 128, 128, 0);
}

.HideButton:focus {
  outline: 0;
}

.SideBar:hover {
  cursor: pointer;
  display: inline-block;
}

.SideBar:hover:after {
  content: "\00ab";
  color: black;
  position: absolute;
  left: 140px;
  top: calc(50vh - 70px);
  transition: 0.5s;
  opacity: 1;
  right: 0;
}

.SideBar.Hidden {
  width: 170px;
  height: calc(100vh - 90px);
  background-color: white;
  margin-top: 20px;
  padding-right: 20px;
  padding-top: 40px;
  padding-bottom: 20px;
  border: 2px solid gray;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  position: absolute;
  left: -145px;
}

.SideBar.Hidden:hover {
  cursor: pointer;
  display: inline-block;
  transition: 0.3s;
}

.SideBar.Hidden:hover:after {
  content: "\00bb";
  color: rgb(97, 97, 97);
  position: absolute;
  left: 140px;
  top: calc(50vh - 70px);
  transition: 0.5s;
  opacity: 1;
  right: 0;
}

.Hidden {
  width: 100vw;
}

.HiddenContent {
  padding-left: 20px;
}

.NavItem.Active {
  color: #e83410;
}
